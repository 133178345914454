import { faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import { faArrowRightArrowLeft, faArrowsRotate, faBug, faBugSlash, faMagnifyingGlass, faMobileScreenButton, faPalette, faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AOS from "aos";
import "aos/dist/aos.css";

const RepairView = ({ className } : { className?: string}) => {
    AOS.init();
    return (
        <div id="repair-service" className={className}>
            <div className="responsive-header">
                <h3 className="text-4xl font-bold mb-4">Dépannage, Réparation et Refonte de sites Web</h3>   
                <p className="mb-8">Agantar <b>répare, modernise et protège</b> votre site web pour garantir des performances optimales et une sécurité renforcée.</p>  
            </div>

                <div className="responsive-grid">

                    <div data-aos="fade-up" className="responsive-card">
                        <h4 className="text-2xl">Refonte 🎨</h4>
                        <p className="pt-2 pb-6 semibold">Modernisez <b>l’apparence et les fonctionnalités</b> de votre site.</p>
                        
                        <div className="responsive-card-list">
                            <div className="responsive-card-list-item">
                                <FontAwesomeIcon icon={faPalette} className="text-xl text-black/80 dark:text-white"/>
                                <p className="m-0">Refonte du design</p>
                            </div>
                            <div className="responsive-card-list-item">
                                <FontAwesomeIcon icon={faArrowRightArrowLeft} className="text-xl text-black/80 dark:text-white"/>
                                <p className="m-0">Migration de contenu</p>
                            </div>
                            <div className="responsive-card-list-item">
                                <FontAwesomeIcon icon={faMobileScreenButton} className="text-xl text-black/80 dark:text-white"/>
                                <p className="m-0">Optimisation pour les mobiles</p>
                            </div>
                        </div>
                        <a href="/contact?q=dr" className="btn p-2 w-full text-center primary">Modernisez votre site</a>
                    </div>


                    <div data-aos="fade-up" className="responsive-card">
                        <h4 className="text-2xl">Dépannage & débuggage 🪳</h4>
                        <p className="pt-2 pb-6 semibold">Corrigez rapidement les <b>problèmes techniques bloquants</b>.</p>
                        
                        <div className="responsive-card-list">
                            <div className="responsive-card-list-item">
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="text-xl text-black/80 dark:text-white"/>
                                <p className="m-0">Diagnostic complet</p>
                            </div>
                            <div className="responsive-card-list-item">
                                <FontAwesomeIcon icon={faBug} className="text-xl text-black/80 dark:text-white"/>
                                <p className="m-0">Résolution des bugs et erreurs</p>
                            </div>
                            <div className="responsive-card-list-item">
                                <FontAwesomeIcon icon={faSquareCheck} className="text-xl text-black/80 dark:text-white"/>
                                <p className="m-0">Test et validation</p>
                            </div>
                        </div>
                        
                        <a href="/contact?q=dd" className="btn p-2 w-full text-center primary">Demander un diagnostic</a>
                    </div>
                

                    <div data-aos="fade-up" className="responsive-card">
                        <h4 className="text-2xl">Sécurisation 🛡️</h4>
                        <p className="pt-2 pb-6 semibold">Protégez votre site contre les menaces et <b>restaurez ses performances</b>.</p>
                        
                       
                        <div className="responsive-card-list">
                            <div className="responsive-card-list-item">
                                <FontAwesomeIcon icon={faBugSlash} className="text-xl text-black/80 dark:text-white"/>
                                <p className="m-0">Suppression des malveillants</p>
                            </div>
                            <div className="responsive-card-list-item">
                                <FontAwesomeIcon icon={faArrowsRotate} className="text-xl text-black/80 dark:text-white"/>
                                <p className="m-0">Mise à jour système et plugins</p>
                            </div>
                            <div className="responsive-card-list-item">
                                <FontAwesomeIcon icon={faShieldHalved} className="text-xl text-black/80 dark:text-white"/>
                                <p className="m-0">Renforcement de la sécurité</p>
                            </div>
                        </div>
                        <a href="/contact?q=ds" className="btn p-2 w-full text-center primary">Demander un diagnostic</a>
                    </div>
            </div>
        </div>
    );
}

export default RepairView;