import React, { useState } from "react";
import { faCircleInfo, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

const Benefits = ({ title, icon, description, recall, last }: { 
    title: string, 
    // FontAwesomeIcon
    icon?: FontAwesomeIconProps['icon'],
    description?: string ,
    recall?: boolean,
    last?: boolean
}) => {
    const [detailVisible, setDetailVisible] = useState(false);
   
    return recall ?
    (
        <div className={`p-2 px-4 border-b bg-gray-50 text-gray-500`}>
            <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faThumbtack} className="text-base"/>
                <p className="text-sm">{title}</p>
            </div>
        </div>
    ) :
    (
        <>
            <div className={`p-3 px-4 ${ last ? 'border-none' : 'border-b'} dark:border-gray-700`}>
                <div className=" flex items-center justify-between">
                    <p className=''>
                    { icon && <><FontAwesomeIcon icon={icon} className='text-agantar-blue pr-1'/> </>}
                        {title}</p>
                    <button
                        onMouseOver={() => setDetailVisible(true)}
                        onMouseLeave={() => setDetailVisible(false)}
                        onTouchStart={() => setDetailVisible(true)}
                        onTouchEnd={() => setDetailVisible(false)}
                        onContextMenu={(e) => e.preventDefault()} // Prevents long-press menu
                        className="touch-none" // Disables selection highlight
                    >
                        <FontAwesomeIcon icon={faCircleInfo} className='text-agantar-blue ml-2'/>
                    </button>
                </div>
                <p className={`text-sm text-black/60 py-1 ${ detailVisible ? 'block' : 'hidden' }`}>{description}</p>
            </div>
        </>
    )
}

export default Benefits;