import React, { useState, ChangeEvent, FormEvent, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFlashMessage } from '../../context/FlashMessageContext';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useSearchParams } from "react-router-dom";

import '../../assets/styles/pages/Contact.css';

// Get query args


interface ContactProps {
  className?: string;
}

interface projectList {
  [key: string]: string;
}

interface Errors {
  name?: string;
  company?: string;  
  email?: string;
  phone?: string;
  subject?: string;
  message?: string;
}

const ContactView: React.FC<ContactProps> = ({ className }) => {
  const { addFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
      name: '',
      company: '',
      email: '',
      phone: '',
      about: '',
      subject: '',
      message: '',
      file: '',
  });
  const [errors, setErrors] = useState<Errors>({});
  const [isSending, setIsSending] = useState(false);
  const [honeyPot, setHoneyPot] = useState(false);

  const [searchParams] = useSearchParams();
  const query = searchParams.get("q"); // Get "paramName" from URL

  const options = [
    { name: 'Choisissez une option', value: '' },
    // Sites
    { name: 'Sites Vitrine', value: 'sv' },
    { name: 'Site Vitrine - One Page', value: 'so' },
    { name: 'Site Vitrine - Essentiel', value: 'se' },
    { name: 'Site Vitrine - Pro', value: 'sp' },
    { name: 'Site E-commerce', value: 'sec' },
    { name: 'Application Web', value: 'aw' },
    // Dépannage
    { name: 'Refonte Site Web', value: 'dr' },
    { name: 'Dépannage et débuggage', value: 'dd' },
    { name: 'Sécurisation', value: 'ds' },
    // Développement
    { name: 'Plugin Wordpress', value: 'pwo' },
    { name: 'Progressive Web App', value: 'pwa' },
    { name: 'Développement sur-mesure', value: 'dsm' },
    // Autre
    { name: 'Autre...', value: 'ot' },
  ];


  const projects = useMemo(() => ({
    sv: 'Sites Vitrine',
    so: 'Site Vitrine - One Page',
    se: 'Site Vitrine - Essentiel',
    sp: 'Site Vitrine - Pro',
    
    sec: 'Site E-commerce',
    aw: 'Application Web',

    dr: 'Refonte Site Web',
    dd: 'Dépannage et débuggage',
    ds: 'Sécurisation',

    pwo: 'Plugin Wordpress',
    pwa: 'Progressive Web App',
    dsm: 'Développement sur-mesure',

    ot: 'Autre...'
  } as projectList), []);
   
  useEffect(() => {
    document.title = "Agantar - Contactez-nous"

    if( query && projects[query] ) {

      const subject = query === 'ot' ? 
      `Demande d'information`
      :
      `Demande d'information pour un projet ${ query === 'aw' ? "d'" : 'de '}${projects[query]}`;


      setFormData(prevState => ({
        ...prevState,
        about: query,
        subject: subject
      }));
    }
  }, [ query, projects ]);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if( value !== '' ) {
      setErrors(prevState => ({
        ...prevState,
        [name]: ''
      }));
    }
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const validate = (): Errors => {
    let tempErrors: Errors = {};   
    if (!formData.name) tempErrors.name = 'Ce champ est obligatoire';    
    if (!formData.email) tempErrors.email = 'Ce champ est obligatoire';
    if (!formData.subject) tempErrors.subject = 'Ce champ est obligatoire';
    if (!formData.message) tempErrors.message = 'Ce champ est obligatoire';
    if (formData.email && !validateEmail(formData.email)) tempErrors.email = 'Adresse email invalide';
    if( formData.phone ) {
      const filtered = formData.phone.replace(/[^0-9+()]/g, '');
      if( !validatePhone(filtered) ) tempErrors.phone = 'Numéro de téléphone invalide';
      else setFormData(prevState => ({
        ...prevState,
        phone: filtered
      }));
    }
    // Additional validation logic
    return tempErrors;
  };

  const validateEmail = (email: string): boolean => {
    const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return re.test(email);
  }

  const validatePhone = (phone: string): boolean => {
    const re = /^[0-9+()]{9,16}$/;
    return re.test(phone);
  }

  // Submit the form
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if( formData.file ) {
      setHoneyPot(true);
      setTimeout(() => {
        addFlashMessage('Votre email à bien été envoyé !', 'success');
        setHoneyPot(false);
        navigate('/', { replace: true });
      }, 2534);
      return;
    }

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);  
      return;
    }
    setErrors({});    
    setIsSending(true);
    
    // EmailJS
    emailjs.send(
      'service_hc9q0gi',
      'template_mu8bj3a',
      formData,
      'qXoTTHBrbvivuGeQT'
    ).then((result) => {
      if( result.text === 'OK' ) {
        addFlashMessage('Votre email à bien été envoyé !', 'success');
        navigate('/', { replace: true });
        setIsSending(false);
      }
    }, (error) => {
      console.log(error.text);
      alert('Une erreur est survenue lors de l\'envoi de l\'email');
      setIsSending(false);
    });

  };

  const inputClass = "w-full p-3 rounded-lg border border-gray-200 dark:bg-white/5 dark:border-black/15 dark:placeholder:text-white/60"
  const fieldError = "!border-red-500 placeholder:text-red-500";

  return (
    <div id="contact">
      <div className="page">

        <h1 className='blue text-center mb-8'>Pour me contacter</h1>

        <p className="xs:text-xl text-center max-w-2xl mx-auto mb-8 px-4">
        Une question ou une idée en tête ?<br/>
        Décrivez-moi votre projet et je reviendrai vers vous rapidement.
        </p>
        
      {/* MODAL LOADING */}
      <div className={`${(isSending || honeyPot) ? '' : 'hidden'} absolute top-0 left-0 h-full w-full fixed z-50 flex items-center justify-center bg-slate-100/50 dark:bg-black/50`}>
        <div className="
            shadow flex gap-x-4 items-center px-6 py-6 rounded-lg border 
            dark:bg-gray-950/80 dark:border-white/10 
            bg-white border-black/5">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" width="20" height="20" className='animate-spin'>
            <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/>
          </svg>
          <span>Envoi en cours</span>
        </div>
      </div>


      {/* CONTACT FORM */}
      <form onSubmit={handleSubmit} className="space-y-2 mb-16 flex-[3] max-w-4xl mx-auto px-4">

          <h4 className="text-xl font-semibold">Votre Projet</h4>
          <div className="form-group">
            <label htmlFor="about">Votre demande concerne</label>
            <select 
              name="about" 
              id="about"
              value={formData.about}
              disabled={isSending}
              onChange={handleChange}
            >
                { options.map((option, index) => (
                  <option key={index} value={option.value}>{option.name}</option>
                ))}
            </select>
          </div>

          <div className="form-group">
          <label htmlFor="subject">Objet*</label>
            <input 
            id="subject"
            name="subject"
            type="text"
            value={formData.subject}
            onChange={handleChange}
            className={`${inputClass} ${errors.subject && fieldError}`}
            disabled={isSending}
            />
            {errors.subject && <span className="text-xs italic text-red-500 pt-1 pl-1">{errors.subject}</span>}
            </div>

          <div className="form-group">
          <label htmlFor="subject">Description de votre projet*</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className={`${inputClass} min-h-32 ${errors.message && fieldError}`}
              disabled={isSending}
              />
              {errors.message && <span className="text-xs italic text-red-500 pt-1 pl-1">{errors.message}</span>}
          </div>


          <h4 className="text-xl font-semibold">Comment vous joindre ?</h4>

          <div className="flex gap-4 flex-col md:flex-row">
            <div className="form-group flex-1">
              <label htmlFor="name">Nom et Prénom*</label>
              <input
                id="name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={`${inputClass} ${errors.name && fieldError}`}
                disabled={isSending}
                />
              {errors.name && <span className="text-xs italic text-red-500 pt-1 pl-1">{errors.name}</span>}
            </div>
            
            <div className="form-group flex-1">
            <label htmlFor="company">Entreprise</label>
              <input
                id="company"
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
                className={inputClass}
                disabled={isSending}
                />
            </div>
          </div>

          <div className="flex gap-4 flex-col md:flex-row">
            <div className="form-group flex-1">
            <label htmlFor="email">Adresse email*</label>
              <input
                id="email"
                name="email"
                type="text"
                value={formData.email}
                onChange={handleChange}
                className={`${inputClass} ${errors.email && fieldError}`}
                disabled={isSending}
                placeholder='user@domain.com'
                />
              {errors.email && <span className="text-xs italic text-red-500 pt-1 pl-1">{errors.email}</span>}
            </div>

            <div className="form-group flex-1">
              <label htmlFor="phone">Téléphone</label>
              <input
                id="phone"
                name="phone"
                type="text"
                value={formData.phone}
                placeholder='1234567890'
                onChange={handleChange}
                className={`${inputClass} ${errors.phone && fieldError}`}
                disabled={isSending}
                />
              {errors.phone && <span className="text-xs italic text-red-500 pt-1 pl-1">{errors.phone}</span>}
            </div>
          </div>

          

          <input 
            id="file"
            name="file"
            type="text"
            className='bg-transparent hidden'
            value={formData.file}
            onChange={handleChange}
            />
            <div className="text-xs pt-2">* Ce champ est nécessaire pour un bon traitement de votre demande.</div>

          <div className="pt-6">
            <button
              type="submit"
              className="btn primary px-4 py-2 ml-auto"
              disabled={isSending}
              >
              Envoyer
            </button>
          </div>
        </form>
      </div>


      <div className="bg-agantar-blue py-16 text-white">
        <div className="page w-4/12">
            <h2 className='text-center text-white mb-6'>Vous préférez un rendez-vous ?</h2>
            <p className='text-center px-4'>Réservez tout de suite un rendez-vous de 30 minutes à la date qui vous convient pour parler de votre projet, en cliquant simplement sur le lien Calendly ci-dessous.</p>

            <a  href="https://calendly.com/gabriel-endeka/30min" target="blank" 
              className="mt-12 block mx-auto border border-black/10 rounded-lg bg-white  p-4 hover:shadow-lg h-fit w-32 h-32 text-center cursor-pointer transition">
              <img src="images/calendly.svg" alt="" className='w-24 h-24 mx-auto'/>
            </a>

          </div>
      </div>


      <div className="page px-4">
        <div className="flex flex-col md:flex-row gap-6 md:items-stretch items-center my-20 md:max-w-full max-w-[400px] mx-auto">
        
            {/* CONTACT TEXT */}
            <div className='border border-slate-200 dark:border-black/30 p-8 rounded-lg grow-1 text-center md:w-fit w-full dark:bg-gray-800/70 '>
              <div className="w-[210px] overflow-hidden mb-2 grayscale mx-auto">
                <img src="images/gaby-mono.webp" alt="" className='object-cover mx-auto'/>
              </div>
              <p className="font-bold mb-0 text-center">Gabriel CONRAIRIE</p>
              <p className="small text-center mb-4">Chef de projet digital</p>

              <p className='mb-1 text-center'>
                <a href="tel:+33658008765" className='text-agantar-blue dark:text-slate-400 hover:text-agantar-light-blue'>
                <FontAwesomeIcon icon={faPhone} className='pr-2'/>
                  +33(0)6.58.00.87.65
                </a>
              </p>
              <p className='mb-1 text-center'>
                <a href="mailto:contact@agantar.com" className='text-agantar-blue dark:text-slate-400 hover:text-agantar-light-blue'>
                <FontAwesomeIcon icon={faEnvelope} className='pr-2'/>
                  contact@agantar.com
                </a>
              </p>
            </div>

            <div className="h-[400px] md:h-auto w-full grow">
              <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.6419160650953!2d2.2280867000000004!3d43.237966799999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ae3120ca02354f%3A0x3355abb2e079135c!2sAgantar!5e0!3m2!1sfr!2sfr!4v1738222188099!5m2!1sfr!2sfr"
                  className='rounded-lg h-full w-full'
                  style={{ border: 0 }} 
                  allowFullScreen 
                  loading="lazy" 
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Maps Location"
                  ></iframe>
            </div>
          </div>
      </div>






    {/* Fin contact */}
    </div>
  );
};

export default ContactView;